import React, { useRef } from 'react';
import { useHistory } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import i18n from '../../i18n';
import { baseSchema, baseUrl, apiKey, dryRun, contextString } from '../../Utils/Constants';
import ReactGA from 'react-ga';
import Dialog from 'react-bootstrap-dialog'
import './UtilBar.css';
import { FaExpandArrowsAlt } from 'react-icons/fa';

const UsernameForm = () => {



    const goFullScreen = (e) => {
        if ((document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)) {
         if (document.documentElement.requestFullScreen) {
           document.documentElement.requestFullScreen();
         } else if (document.documentElement.mozRequestFullScreen) {
           document.documentElement.mozRequestFullScreen();
         } else if (document.documentElement.webkitRequestFullScreen) {
           document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
         }
       } else {
         if (document.cancelFullScreen) {
           document.cancelFullScreen();
         } else if (document.mozCancelFullScreen) {
           document.mozCancelFullScreen();
         } else if (document.webkitCancelFullScreen) {
           document.webkitCancelFullScreen();
         }
       }

    }

    return (

        <>
            <div className="utilBarWrapper">
                <Button className="bg-transparent utilBtn" onClick={goFullScreen}>
                    <FaExpandArrowsAlt />
                </Button>
            </div>

        </>

    );
}

export default UsernameForm;
