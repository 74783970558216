import React, { useRef } from 'react';
import { useHistory } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import './UsernameForm.css';
import i18n from '../../i18n';
import { baseSchema, baseUrl, apiKey, dryRun, contextString } from '../../Utils/Constants';
import ReactGA from 'react-ga';
import Dialog from 'react-bootstrap-dialog'

const UsernameForm = ({ username, setUsername, setHash, setFeedbackId }) => {
    const history = useHistory();

    let dialog = useRef(null);


    const goToAnalysis = (e) => {
        if (username && username !== '') {

            let analyticsClientId = '';
            ReactGA.ga((tracker) => {
                analyticsClientId = tracker.get('clientId');
            });


            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'x-api-key': apiKey, 'x-dry-run': dryRun },
                body: JSON.stringify({ context: contextString, username, lang: i18n.language, userGanalyticsId: analyticsClientId })
            };

            fetch(`${baseSchema}://${baseUrl}/initfeedback`, requestOptions)
                .then(response => response.json())
                .then(async data => {
                    setHash(data.h);
                    setFeedbackId(data.feedback_id);

                    history.push('/analysis')
                });
        }
        else {
            dialog.showAlert(i18n.t('Ensure you have entered a username!'))
        }

    }

    return (

        <>

            <Form className="UserEntryForm">
                <Form.Group controlId="formUserName">
                    <Form.Control type="text" placeholder={i18n.t('Enter Username')} onChange={e => setUsername(e.target.value)} />
                </Form.Group>
                <Button variant="warning" size="lg" block onClick={(e) => goToAnalysis(e)}>{i18n.t('Start')}</Button>{' '}
            </Form>

            <Dialog ref={(el) => { dialog = el }} />

        </>

    );
}

export default UsernameForm;
