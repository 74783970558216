import React from 'react';
import './AnalysisPage.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CaptureEmotionsWithTimer from '../../Components/CaptureEmotionsWithTimer/CaptureEmotionsWithTimer';
import i18n from '../../i18n';
import { baseSchema, baseUrl, apiKey, dryRun, contextString } from '../../Utils/Constants';
import ReactGA from 'react-ga';
import Log from '../../Utils/Log';
import UtilBar from '../../Components/UtilBar/UtilBar'

const AnalysisPage = ({ username, feedbackId, hash }) => {


    const saveAnalysis = (analysis) => {

        console.log(analysis)
        let analyticsClientId = '';
        ReactGA.ga((tracker) => {
            analyticsClientId = tracker.get('clientId');
        });


        let submitData = {
            feedback_id: feedbackId,
            h: hash,
            lang: i18n.language,
            userGanalyticsId: analyticsClientId,
            ageEstimation: analysis.age,
            genderEstimation: analysis.gender,
            emotions: analysis.emotions,
            isFinal: analysis.ended
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-api-key': apiKey, 'x-dry-run': dryRun },
            body: JSON.stringify(submitData)
        };

        fetch(`${baseSchema}://${baseUrl}/saveemotionschunk`, requestOptions)
            .then((response) => {
                return response.json();
            })
            .then(data => {
                Log.debug(data);
            }).catch(error => {
                Log.error(error, `saveAnalysis`);
            });
    }


    return (
        <>
        <UtilBar />
        <Container className="analysisContainer">
            <Row>
                <Col className="text-center">

                    <CaptureEmotionsWithTimer saveAnalysis={saveAnalysis} username={username} feedbackId={feedbackId} hash={hash} />

                </Col>
            </Row>

        </Container>
        </>

    );
}

export default AnalysisPage;
