import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import './CaptureEmotionsWithTimer.css';
import Log from '../../Utils/Log';
import * as faceapi from 'face-api.js'
import i18n from '../../i18n';
import moment from 'moment';
import momentDurationFormatSetup from "moment-duration-format";
import { FaRetweet } from 'react-icons/fa';


momentDurationFormatSetup(moment);

// Intervals

let intervalID = 0;
let timerInterval = 0;
let smiletimeoutId = 0;
let nonStateTimeHack = '00:00:00';



let countFaceNotDetectedInARow = 0;
let liveEmotions = {};
let predictedAges = [];
let meStream;
let areModelsLoaded = false;
let timerStart = 0;
let missingFace = false; // true if emotions cannot be retrieved.
let biggestEmotion = ''; // used for the circle border color

const nnUsed = 'tinyFaceDetector';
const scoreThresholdHaalia = 0.5; // default value 0.5
const smileTimeout = 35000; // no of ms to move to step2 even without smile
const facescanIntervalInMs = 1000;
const happyThreshold = 0.7;
const showMainEmotionBorderColor = false; // false = always blue. true = border color of the main emotion

// Block
let _age = null;
let _gender = null;

const CaptureEmotionsWithTimer = ({ username, saveAnalysis, feedbackId, hash }) => {
    const history = useHistory();

    // State Hooks
    const [hasSmiled, setHasSmiled] = useState('');
    const [currentTime, setCurrentTime] = useState('');
    const [currentStatus, setCurrentStatus] = useState(i18n.t('Loading..'));
    const [isRunning, setIsRunning] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [mediaDevices, setMediaDevices] = useState([]);
    const [mediaId, setMediaId] = useState('');
    const [facingMode, setFacingMode] = useState('user');



    // Refs
    let videoMe = useRef(null);
    let overlayCanvas = useRef(null);

    // SideEffect Hooks
    useEffect(() => {
        setCurrentTime(moment.duration(0, 'seconds').format('hh:mm:ss', { trim: false })); nonStateTimeHack = '00:00:00'
        _age = null;
        _gender = null;
        timerStart = 0;

        Promise.all([
            (nnUsed === 'tinyFaceDetector' ?
                faceapi.nets.tinyFaceDetector.loadFromUri('/models') :
                faceapi.nets.ssdMobilenetv1.loadFromUri('/models')),

            faceapi.nets.faceExpressionNet.loadFromUri('/models'),
            faceapi.nets.ageGenderNet.loadFromUri("/models")
        ])
            .then(async () => {
                Log.info(`NN models loaded successfully`, `CaptureEmotionsWithTimer`)
                areModelsLoaded = true;
                //startVideoMe(null);
            })
            .catch(error => {
                Log.error(error, `Loading NN`);
            });

        navigator.mediaDevices.getUserMedia({video: true, audio:false}).then(m => {
            navigator.mediaDevices.enumerateDevices().then(devices => {
                console.log(devices)
                const videoDevices = devices.filter(device => device.kind == 'videoinput');
                if(videoDevices.length > 0) {
                    setMediaDevices(videoDevices);
                    startVideoMe(videoDevices[0].deviceId);
                }

            });
        })



        return () => {
            clearInterval(intervalID);
            clearInterval(timerInterval);
        }
    }, []);

    const isIos = () => {
        let isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
            (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && // ipad
            !window.MSStream // to hack last windows Edge that containe "iPhone" (seems to hack some gmail restriction on edge)

        return isIOS;
    }

    const startVideoMe = (streamId) => {
        setMediaId(streamId);
        var streamConfig = { video: { facingMode: facingMode }, audio: false }
        if(streamId != null) {
            streamConfig.video.deviceId = {};
            streamConfig.video.deviceId.exact = streamId;
        }
        console.log(streamConfig)

        if (!isIos()) {
            navigator.mediaDevices.getUserMedia(streamConfig).then((stream) => setVideoMeStream(stream)).catch((err) => videoMeError(err))
        }
        else {
            if (navigator.mediaDevices) {
                streamConfig.video.width = 640;

                navigator.mediaDevices.getUserMedia(streamConfig).then(function (stream) {
                    setVideoMeStream(stream);
                }).catch(function (err) {
                    Log.error(err, `Loading Cam IOS`);
                });
            }
        }
    }

    const startTimer = () => {
        setIsRunning(true);
        setCurrentStatus(i18n.t('Running..'))
        timerStart = moment();
        timerInterval = setInterval(() => {
            let timeNow = moment();
            let duration = timeNow - timerStart;
            let currTime = moment.duration(duration, 'milliseconds').format('hh:mm:ss', { trim: false });
            nonStateTimeHack = currTime;
            setCurrentTime(currTime);
        }, 1000)
    }

    const stopTimer = () => {
        clearInterval(timerInterval);
        clearInterval(intervalID);
        saveLatestEmotions(true);
        setCurrentTime(moment.duration(0, 'seconds').format('hh:mm:ss', { trim: false }));
        timerStart = 0;
        missingFace = true;
        biggestEmotion = '';


        history.push('/thanks')
    }

    const clearVideoMeStream = () => {

        if(meStream) {
        meStream.getTracks().forEach(track => {
            track.stop();
          });

        }

        meStream = null;
        videoMe.current.srcObject = null;
    }

    const setVideoMeStream = (stream) => {

        try {
            meStream = stream;
            videoMe.current.srcObject = meStream;
        }
        catch (ex) {
            Log.error(ex, `setVideoMeStream`);
        }
    }

    const delegateHasSmiled = (happyEmotion) => {
        if ((hasSmiled === false && (happyEmotion > happyThreshold))) {
            clearTimeout(this.smiletimeoutId);
            setHasSmiled(true);
        }
    }


    const videoMeStarted = async () => {

        const displaySize = { width: 280, height: 210 }
        faceapi.matchDimensions(overlayCanvas.current, displaySize)

        // smiletimeoutId = setTimeout(() => {
        //     Log.debug('happyTimeout thrown. No smile detected so far', 'smileTimeOut');
        //     setHasSmiled(true);
        // }, smileTimeout)
        const detectionOptions = nnUsed === 'tinyFaceDetector' ?
            new faceapi.TinyFaceDetectorOptions({ scoreThreshold: scoreThresholdHaalia }) :
            new faceapi.SsdMobilenetv1Options({ minConfidence: scoreThresholdHaalia });


        intervalID = setInterval(() => {
            try {

                if (!areModelsLoaded) {
                    Log.info('NN not loaded so far', `videoMeStarted`);
                }
                else {
                    if (_age == null) {

                        faceapi.detectSingleFace(videoMe.current, detectionOptions).withAgeAndGender()
                            .then((initialDetection) => {

                                if (initialDetection != null) {
                                    countFaceNotDetectedInARow = 0;
                                    const age = initialDetection.age;
                                    const interpolatedAge = interpolateAgePredictions(age);
                                    const genderDetected = initialDetection.gender;

                                    Log.debug(`Detected Age: ${interpolatedAge} and Gender: ${genderDetected}`, `withAgeAndGender`);
                                    _age = (interpolatedAge.toFixed(0));
                                    _gender = (genderDetected);
                                    setCurrentStatus(i18n.t('Loaded. Ready.'));
                                    setIsReady(true);
                                    missingFace = false;
                                    biggestEmotion = '';
                                }
                                else {
                                    countFaceNotDetectedInARow++;
                                    missingFace = true;
                                    biggestEmotion = '';
                                    Log.debug(`Face not detected (age/gender not collected)`, `withAgeAndGender`);
                                    // if (countFaceNotDetectedInARow > 10) {
                                    //     Log.error(`It seems we cannot detect age+gender. Skip it and detect emotions`, `withAgeAndGender`);
                                    //     _age = -1;
                                    //     setCurrentStatus(i18n.t('Loaded. Ready.'));
                                    //     setIsReady(true);

                                    // }
                                }
                            })
                            .catch((error) => {
                                Log.error(error, 'withAgeAndGender');
                                missingFace = true;
                                biggestEmotion = '';
                            });
                    }
                    else {



                        faceapi.detectSingleFace(videoMe.current, detectionOptions).withFaceExpressions()
                            .then((detections) => {

                                if (detections != null) {
                                    countFaceNotDetectedInARow = 0;
                                    missingFace = false;

                                    // draw blue rectangle on face + emotion
                                    const resizedDetections = faceapi.resizeResults(detections, displaySize)
                                    overlayCanvas.current.getContext('2d').clearRect(0, 0, overlayCanvas.current.width, overlayCanvas.current.height)
                                    faceapi.draw.drawDetections(overlayCanvas.current, resizedDetections)
                                    //faceapi.draw.drawFaceExpressions(overlayCanvas.current, resizedDetections)


                                    var detectionStats = {

                                        angry: detections.expressions.angry.toFixed(5),
                                        sad: detections.expressions.sad.toFixed(5),
                                        happy: detections.expressions.happy.toFixed(5),
                                        neutral: detections.expressions.neutral.toFixed(5),
                                        fearful: detections.expressions.fearful.toFixed(5),
                                        surprised: detections.expressions.surprised.toFixed(5),
                                        disgusted: detections.expressions.disgusted.toFixed(5),

                                    };

                                    if (showMainEmotionBorderColor) {
                                        // used for the circle border color
                                        biggestEmotion = 'neutral';
                                        let biggestEmotionVal = detectionStats.neutral;
                                        if (detectionStats.angry > biggestEmotionVal) {
                                            biggestEmotionVal = detectionStats.angry;
                                            biggestEmotion = 'angry';
                                        }
                                        if (detectionStats.sad > biggestEmotionVal) {
                                            biggestEmotionVal = detectionStats.sad;
                                            biggestEmotion = 'sad';
                                        }
                                        if (detectionStats.happy > biggestEmotionVal) {
                                            biggestEmotionVal = detectionStats.happy;
                                            biggestEmotion = 'happy';
                                        }
                                        if (detectionStats.fearful > biggestEmotionVal) {
                                            biggestEmotionVal = detectionStats.fearful;
                                            biggestEmotion = 'fearful';
                                        }
                                        if (detectionStats.surprised > biggestEmotionVal) {
                                            biggestEmotionVal = detectionStats.surprised;
                                            biggestEmotion = 'surprised';
                                        }
                                        if (detectionStats.disgusted > biggestEmotionVal) {
                                            biggestEmotionVal = detectionStats.disgusted;
                                            biggestEmotion = 'disgusted';
                                        }

                                        //delegateHasSmiled(detectionStats.happy);
                                        Log.debug('biggest emotion[' + biggestEmotion + ']', detectionStats);
                                    }
                                    else {
                                        Log.debug(detectionStats);
                                    }

                                    let tStamp = nonStateTimeHack;
                                    liveEmotions[tStamp] = detectionStats;
                                    // ES6 Check
                                    console.log(liveEmotions)
                                    console.log(Object.keys(liveEmotions).length)
                                    if (Object.keys(liveEmotions).length >= 10) {
                                        saveLatestEmotions(false);
                                    }

                                }
                                else {
                                    countFaceNotDetectedInARow++;
                                    missingFace = true;
                                    Log.error(`Face not detected (emotions not collected)`, `withFaceExpressions`);
                                    if (countFaceNotDetectedInARow > 4) {
                                        overlayCanvas.current.getContext('2d').clearRect(0, 0, overlayCanvas.current.width, overlayCanvas.current.height)
                                        Log.error(`User seems out of screen (emotions not collected)`, `withFaceExpressions`);
                                    }
                                }
                            })
                            .catch((error) => {
                                Log.error(error, `withFaceExpressions`);
                                missingFace = true;
                                biggestEmotion = '';
                            });
                    }
                }
            }
            catch (err) {
                Log.error(err);
                missingFace = true;
                biggestEmotion = '';
            }
        }, facescanIntervalInMs)
    }

    // Save emotion chink and clear out array. It will get logged if error so as not to lose
    const saveLatestEmotions = async (ended) => {
        let emotionsToSend = liveEmotions;
        liveEmotions = {};

        const payload =
        {
            emotions: emotionsToSend,
            age: _age,
            gender: _gender,
            ended
        }

        saveAnalysis(payload);
    }

    const videoMeError = (exceptionModel) => {
        missingFace = true;
        biggestEmotion = '';
        Log.error('error while trying to load webcam', `videoMeError`);
        Log.error(exceptionModel, `videoMeError`);
    }



    const interpolateAgePredictions = (age) => {
        predictedAges = [age].concat(predictedAges).slice(0, 30);
        const avgPredictedAge =
            predictedAges.reduce((total, a) => total + a) / predictedAges.length;
        return avgPredictedAge;
    }

    const changeCamera = (e) => {
        console.log(e.target.value)
        clearVideoMeStream();
        startVideoMe(e.target.value);
    }

    const reverseCamera = () => {

        console.log('canera reverse')

        setFacingMode(facingMode=='user'? 'environment' : 'user');
        clearVideoMeStream();
        startVideoMe(mediaId);
    }

    const videoMePaused = (event) => { event.preventDefault(); videoMe.current.play(); };


    return (

        <Container className="analysisContainer">
            <Col>
                <Row>
                    <Col className="text-center">
                        <div className={"circle" + (missingFace && isRunning ? ' missingFace' : (isRunning ? ' faceOK biggestEmotion' + biggestEmotion : ''))}>
                            <p style={{ fontSize: 20 }}>{username}&nbsp;</p>
                            <hr />
                            <p style={{ fontSize: 50 }}>{currentTime}</p>
                            <p style={{ fontSize: 14 }}>{currentStatus}</p>

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form className="UserEntryForm">
                            {isRunning ?
                                <Button variant="warning" size="lg" onClick={stopTimer} block >{i18n.t('Stop')}</Button>
                                :
                                <Button variant="warning" size="lg" disabled={!isReady} onClick={startTimer} block >{i18n.t('Start')}</Button>
                            }
                        </Form>
                    </Col>
                </Row>
            </Col>
            <Col className="videoCol">
                <Row>

                    <Form>

                        <Form.Group>
                            <Form.Control as="select" size="lg" custom onChange={changeCamera} >

                                {mediaDevices.map((e,key) => {
                                return <option key={key} value={e.deviceId} selected={key==0}>{e.label.toString().toUpperCase()}</option>;
                                })}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Row>
                <Row>

                    <Col className="text-center showLiveVideo">
                        <video ref={videoMe} onPause={videoMePaused} onLoadedMetadata={videoMeStarted} id="videome" autoPlay muted width="280" height="210"></video>
                        <canvas ref={overlayCanvas} id='overlay' className="canvasOnScreen"></canvas>
                        {/* <Button className="bg-transparent reverseCamera" onClick={reverseCamera}>
                            <FaRetweet />
                        </Button> */}


                    </Col>
                </Row>
            </Col>
        </Container>

    );
}

export default CaptureEmotionsWithTimer;