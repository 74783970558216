import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga';
import { gaKey, gtmKey } from './Utils/Constants'

if (process.env.NODE_ENV !== 'production') {
  localStorage.setItem('debug', 'fatweb:*');
}

const tagManagerArgs = {
    gtmId: gtmKey
}

TagManager.initialize(tagManagerArgs)

ReactGA.initialize(gaKey);


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


serviceWorker.unregister();
