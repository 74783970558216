import React from 'react';
import i18n from '../i18n';

import { GradientDefs, DiscreteColorLegend } from 'react-vis';
import i18next from 'i18next';

const ITEMS = [
  {title: i18n.t('emotion_happy'), color: "green", },
  {title: i18n.t('emotion_surprised'), color: 'orange'},
  {title: i18n.t('emotion_sad'), color: 'red'},
  {title: i18n.t('emotion_angry'), color: 'darkred'},
  {title: i18n.t('emotion_disgusted'), color: 'purple'},
  {title: i18n.t('emotion_fearful'), color: 'hotpink'},
  {title: i18n.t('emotion_neutral'), color: '#4545a0'},
];

export default function TreeMapLegend() {
  return (
    <div className="treemapLegendContainer">
      <DiscreteColorLegend orientation="horizontal"  items={ITEMS} />
    </div>
  );
}