import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar'
import StartPage from './Pages/StartPage/StartPage';
import AnalysisPage from './Pages/AnalysisPage/AnalysisPage';
import ThanksPage from './Pages/ThanksPage/ThanksPage';
import ResultsPage from './Pages/ResultsPage/ResultsPage';
import AdminPage from './Pages/AdminPage/AdminPage';
import { partnerImgPath, headerTitle } from './Utils/Constants';

function App() {

  const [username, setUsername] = useState('');
  const [hash, setHash] = useState('');
  const [feedbackId, setFeedbackId] = useState('');

  return (
    <>
      <div className="bgImage"></div>
      <div className="mainContainer">

        <Navbar bg="light" variant="light">
          <Navbar.Brand href="/">
            <img
              alt=""
              src="/img/logo-dark.png"
              width="190"
              className="d-inline-block align-middle"
            />{' '}
          </Navbar.Brand>
          <h2 className="titlebar">{headerTitle}</h2>
            <img
              alt=""
              src={partnerImgPath}
              className="d-inline-block partnerImg align-right"
            />{' '}
        </Navbar>

        <Router>
          <Switch>
            <Route path="/analysis">
              <AnalysisPage username={username} hash={hash} feedbackId={feedbackId} />
            </Route>
            <Route path="/thanks">
              <ThanksPage hash={hash} feedbackId={feedbackId}/>
            </Route>
            <Route path="/results">
              <ResultsPage />
            </Route>
            <Route path="/admin">
              <AdminPage />
            </Route>
            <Route path="/">
              <StartPage username={username} setUsername={setUsername} setFeedbackId={setFeedbackId} setHash={setHash} />
            </Route>

          </Switch>
        </Router>
      </div>
    </>
  );
}

export default App;
