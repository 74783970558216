import React from 'react';
import './StartPage.css'
import UsernameForm from '../../Components/UsernameForm/UsernameForm';
import { partnerImgPath } from '../../Utils/Constants';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import i18n from '../../i18n';
import UtilBar from '../../Components/UtilBar/UtilBar'


const StartPage = ({username, setUsername, setHash, setFeedbackId}) => {

    return (

        <>
        <UtilBar />
        <Container className="StartContainer">

            <Row>
                <Col className="text-center">
                    <img src={partnerImgPath} alt=""/>
                </Col>
            </Row>
            <Row style={{marginTop: 40}}>
                <Col className="text-center">
                    <p>{i18n.t('Enter Your Username Below..')}</p>
                    <p>{i18n.t('..Then Click Start')}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <UsernameForm username={username} setUsername={setUsername} setHash={setHash} setFeedbackId={setFeedbackId} />
                </Col>
            </Row>
        </Container>

        </>
    );
}

export default StartPage;
