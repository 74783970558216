import React from 'react';
import { useHistory } from "react-router-dom";
import './ThanksPage.css'
import { contextString, partnerImgPath } from '../../Utils/Constants';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import i18n from '../../i18n';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


const ThanksPage = ({ feedbackId, hash }) => {
    const history = useHistory();

    const startAgain = () => {history.push('/')}
    const gotoResults = () => {history.push('/results?context='+contextString+'&feedback_id='+feedbackId+'&h='+hash)}


    return (

        <Container className="StartContainer">
            <Row>
                <Col className="text-center">
                    <img src={partnerImgPath} alt=""/>
                </Col>
            </Row>
            <Row style={{ marginTop: 40, marginLeft: 'auto', marginRight: 'auto', width: "80%" }}>
                <Col className="text-center">
                    <p>{i18n.t('Thanks. The results have been saved. You can start again by clicking below')}</p>
                </Col>
            </Row>
            <Row style={{ marginTop: 10, marginLeft: 'auto', marginRight: 'auto', width: "80%" }}>
                {feedbackId!=null && feedbackId!='' && hash!=null && <Col className="col-sm-6">                    
                    <Form className="UserEntryForm">
                        <Button variant="warning" size="lg" onClick={gotoResults} block >{i18n.t('See report')}</Button>{' '}
                    </Form>
                </Col>
                }
                <Col className={feedbackId!=''?"col-sm-6":""}>
                    <Form className="UserEntryForm">
                        <Button variant="warning" size="lg" onClick={startAgain} block >{i18n.t('Start Again')}</Button>{' '}
                    </Form>
                </Col>
            </Row>
        </Container>

    );
}

export default ThanksPage;
