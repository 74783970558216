import React, { useState, useRef } from 'react';
import './AdminPage.css'
import { baseSchema, baseUrl, adminPass, contextString } from '../../Utils/Constants';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import i18n from '../../i18n';
import Dialog from 'react-bootstrap-dialog'
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";


const AdminPage = () => {

    const [passwordCorrect, setPasswordCorrect] = useState(false);
    const [password, setPassword] = useState('');
    const [fromDate, setFromDate] = useState(new Date(new Date().setHours(0,0,0,0)));
    const [toDate, setToDate] = useState(new Date());
    const [mergeFromRealTime, setMergeFromRealTime] = useState(false);

    let dialog = useRef(null);

    let apiUrlGetReportCsv = baseSchema + '://' + baseUrl + '/getreportcsv?context=' + contextString;
    let csvFileName = 'haaliai-' + contextString;

    let resultsPageBaseUrl = 'results?context=' + contextString;

    const checkPassword = (e) => {
        if (password == adminPass) {
            setPasswordCorrect(true);
        }
        else {
            dialog.showAlert(i18n.t('Password Error!'))
        }
    }

    const getParams = () => {
        let qString = '&';
        qString += `from=${fromDate.toISOString()}`;
        qString += `&to=${toDate.toISOString()}`;
        qString += `&mergeFromRealTime=${mergeFromRealTime == true ? 1 : 0}`

        return qString
    }


    return (
        passwordCorrect ? (
            <div className="adminContainerDiv">
                <Container className="csvgeneratorContainer">
                    <Row className="rowTitle">
                        <Col className="text-center" lg={2}>
                        </Col>
                        <Col className="text-center" lg={3}>
                            <span style={{ color: '#fff', display: 'inline' }}>From:</span> <Datetime value={fromDate} onChange={(ev => setFromDate(ev))} style={{ display: 'inline'}} />
                        </Col>
                        <Col className="text-center" lg={3}>
                            <span style={{ color: '#fff', display: 'inline' }}>To:</span> <Datetime value={toDate} onChange={(ev => setToDate(ev))} />
                        </Col>
                        <Col className="text-center" lg={2} style={{lineHeight: 5.5}}>
                            <input type='checkbox' checked={mergeFromRealTime} onChange={(ev) => {
                                console.log(ev.target.checked)
                                setMergeFromRealTime(ev.target.checked)
                            }} ></input>
                            <span style={{ color: '#fff', display: 'inline', marginLeft: 10 }}>Sync Results with Real Time</span>
                        </Col>
                        <Col className="text-center" lg={2}>
                        </Col>
                    </Row>
                    <Row className="rowTitle">
                        <Col className="text-center">
                            <h2>{i18n.t('Generate CSV agregated data reports')}</h2>
                        </Col>
                    </Row>
                    <Row className="rowAll">
                        <Col className="text-center">
                            <a class="btn btn-primary" href={apiUrlGetReportCsv + '&filename=' + csvFileName + getParams()} target="_blank" role="button">{i18n.t('All data')}</a>
                        </Col>
                    </Row>

                    <Row className="rowAgeRanges">
                        <Col className="text-center">
                            &nbsp;
                    </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={apiUrlGetReportCsv + '&age_range=-18&filename=' + csvFileName + '-all_genders_minus_18' + getParams()} target="_blank" role="button">{i18n.t('All genders -18')}</a>
                        </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={apiUrlGetReportCsv + '&age_range=18-24&filename=' + csvFileName + '-all_genders_18_24' + getParams()} target="_blank" role="button">{i18n.t('All genders 18-24')}</a>
                        </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={apiUrlGetReportCsv + '&age_range=25-44&filename=' + csvFileName + '-all_genders_25_44' + getParams()} target="_blank" role="button">{i18n.t('All genders 25-44')}</a>
                        </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={apiUrlGetReportCsv + '&age_range=45-64&filename=' + csvFileName + '-all_genders_45_64' + getParams()} target="_blank" role="button">{i18n.t('All genders 45-64')}</a>
                        </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={apiUrlGetReportCsv + '&age_range=+65&filename=' + csvFileName + '-all_genders_plus_65' + getParams()} target="_blank" role="button">{i18n.t('All genders +65')}</a>
                        </Col>
                    </Row>

                    <Row className="rowMens">
                        <Col className="text-center">
                            <a class="btn btn-primary" href={apiUrlGetReportCsv + '&gender=M&filename=' + csvFileName + '-all_mens' + getParams()} target="_blank" role="button">{i18n.t('All mens')}</a>
                        </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={apiUrlGetReportCsv + '&gender=M&age_range=-18&filename=' + csvFileName + '-all_mens_minus_18' + getParams()} target="_blank" role="button">{i18n.t('All mens -18')}</a>
                        </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={apiUrlGetReportCsv + '&gender=M&age_range=18-24&filename=' + csvFileName + '-all_mens_18_24' + getParams()} target="_blank" role="button">{i18n.t('All mens 18-24')}</a>
                        </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={apiUrlGetReportCsv + '&gender=M&age_range=25-44&filename=' + csvFileName + '-all_mens_25_44' + getParams()} target="_blank" role="button">{i18n.t('All mens 25-44')}</a>
                        </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={apiUrlGetReportCsv + '&gender=M&age_range=45-64&filename=' + csvFileName + '-all_mens_45_64' + getParams()} target="_blank" role="button">{i18n.t('All mens 45-64')}</a>
                        </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={apiUrlGetReportCsv + '&gender=M&age_range=+65&filename=' + csvFileName + '-all_mens_plus_65' + getParams()} target="_blank" role="button">{i18n.t('All mens +65')}</a>
                        </Col>
                    </Row>

                    <Row className="rowWomens">
                        <Col className="text-center">
                            <a class="btn btn-primary" href={apiUrlGetReportCsv + '&gender=F&filename=' + csvFileName + '-all_womens' + getParams()} target="_blank" role="button">{i18n.t('All womens')}</a>
                        </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={apiUrlGetReportCsv + '&gender=F&age_range=-18&filename=' + csvFileName + '-all_womens_minus_18' + getParams()} target="_blank" role="button">{i18n.t('All womens -18')}</a>
                        </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={apiUrlGetReportCsv + '&gender=F&age_range=18-24&filename=' + csvFileName + '-all_womens_18_24' + getParams()} target="_blank" role="button">{i18n.t('All womens 18-24')}</a>
                        </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={apiUrlGetReportCsv + '&gender=F&age_range=25-44&filename=' + csvFileName + '-all_womens_25_44' + getParams()} target="_blank" role="button">{i18n.t('All womens 25-44')}</a>
                        </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={apiUrlGetReportCsv + '&gender=F&age_range=45-64&filename=' + csvFileName + '-all_womens_45_64' + getParams()} target="_blank" role="button">{i18n.t('All womens 45-64')}</a>
                        </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={apiUrlGetReportCsv + '&gender=F&age_range=+65&filename=' + csvFileName + '-all_womens_plus_65' + getParams()} target="_blank" role="button">{i18n.t('All womens +65')}</a>
                        </Col>
                    </Row>
                </Container>



                <Container className="csvgeneratorContainer">
                    <Row className="rowTitle">
                        <Col className="text-center">
                            <h2>{i18n.t('Generate CSV raw data reports')}</h2>
                        </Col>
                    </Row>
                    <Row className="rowAll">
                        <Col className="text-center">
                            <a class="btn btn-primary" href={apiUrlGetReportCsv + '&rawData=1&filename=' + csvFileName + '-allRawDataPerSession'} target="_blank" role="button">{i18n.t('All raw data per session')}</a>
                        </Col>
                    </Row>
                </Container>



                <Container className="resultChartsContainer">
                    <Row className="rowTitle">
                        <Col className="text-center">
                            <h2>{i18n.t('Display agregated data reports charts')}</h2>
                        </Col>
                    </Row>

                    <Row className="rowAll">
                        <Col className="text-center">
                            <a class="btn btn-primary" href={resultsPageBaseUrl} target="_blank" role="button">{i18n.t('All data')}</a>
                        </Col>
                    </Row>

                    <Row className="rowAgeRanges">
                        <Col className="text-center">
                            &nbsp;
                    </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={resultsPageBaseUrl + '&age_range=-18'} target="_blank" role="button">{i18n.t('All genders -18')}</a>
                        </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={resultsPageBaseUrl + '&age_range=18-24'} target="_blank" role="button">{i18n.t('All genders 18-24')}</a>
                        </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={resultsPageBaseUrl + '&age_range=25-44'} target="_blank" role="button">{i18n.t('All genders 25-44')}</a>
                        </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={resultsPageBaseUrl + '&age_range=45-64'} target="_blank" role="button">{i18n.t('All genders 45-64')}</a>
                        </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={resultsPageBaseUrl + '&age_range=+65'} target="_blank" role="button">{i18n.t('All genders +65')}</a>
                        </Col>
                    </Row>

                    <Row className="rowMens">
                        <Col className="text-center">
                            <a class="btn btn-primary" href={resultsPageBaseUrl + '&gender=M'} target="_blank" role="button">{i18n.t('All mens')}</a>
                        </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={resultsPageBaseUrl + '&gender=M&age_range=-18'} target="_blank" role="button">{i18n.t('All mens -18')}</a>
                        </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={resultsPageBaseUrl + '&gender=M&age_range=18-24'} target="_blank" role="button">{i18n.t('All mens 18-24')}</a>
                        </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={resultsPageBaseUrl + '&gender=M&age_range=25-44'} target="_blank" role="button">{i18n.t('All mens 25-44')}</a>
                        </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={resultsPageBaseUrl + '&gender=M&age_range=45-64'} target="_blank" role="button">{i18n.t('All mens 45-64')}</a>
                        </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={resultsPageBaseUrl + '&gender=M&age_range=+65'} target="_blank" role="button">{i18n.t('All mens +65')}</a>
                        </Col>
                    </Row>

                    <Row className="rowWomens">
                        <Col className="text-center">
                            <a class="btn btn-primary" href={resultsPageBaseUrl + '&gender=F'} target="_blank" role="button">{i18n.t('All womens')}</a>
                        </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={resultsPageBaseUrl + '&gender=F&age_range=-18'} target="_blank" role="button">{i18n.t('All womens -18')}</a>
                        </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={resultsPageBaseUrl + '&gender=F&age_range=18-24'} target="_blank" role="button">{i18n.t('All womens 18-24')}</a>
                        </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={resultsPageBaseUrl + '&gender=F&age_range=25-44'} target="_blank" role="button">{i18n.t('All womens 25-44')}</a>
                        </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={resultsPageBaseUrl + '&gender=F&age_range=45-64'} target="_blank" role="button">{i18n.t('All womens 45-64')}</a>
                        </Col>
                        <Col className="text-center">
                            <a class="btn btn-primary" href={resultsPageBaseUrl + '&gender=F&age_range=+65'} target="_blank" role="button">{i18n.t('All womens +65')}</a>
                        </Col>
                    </Row>
                </Container>
            </div>
        ) : (
            <Container className="StartContainer">
                <Row>
                    <Col className="text-center">
                        <Form className="PasswordForm" onSubmit={(e) => checkPassword(e)}>
                            <Form.Group controlId="formPassword">
                                <Form.Control type="text" placeholder={i18n.t('Enter Password')} type='password' onChange={e => setPassword(e.target.value)} />
                            </Form.Group>
                            <Button variant="warning" size="lg" block onClick={(e) => checkPassword(e)} autoFocus>{i18n.t('Submit')}</Button>{' '}
                        </Form>

                    </Col>

                </Row>

                <Dialog ref={(el) => { dialog = el }} />
            </Container>

        )


    );
}

export default AdminPage;
