export const baseSchema = "https"
export const baseUrl = "api-haalia.revelis.fr";
export const apiKey = "fatweb_23=j6+q6Z#vS4#wPVT";
export const dryRun = "0";
export const contextString = "fusionLabVisioJuly2021";
export const headerTitle = "RealTime Capture Client";
export const partnerImgPath = "";
export const gaKey = "UA-162111085-1";
export const gtmKey = "GTM-N9QX5S3";
export const adminPass = "nerdDemo123";

// export const baseSchema = "https"
// export const baseUrl = "api.haalia.ai";
// export const apiKey = "fatweb_23=j6+q6Z#vS4#wPVT";
// export const dryRun = "0";
// export const contextString = "fusionLabVisioJuly2021";
// export const headerTitle = "RealTime Capture Client";
// export const partnerImgPath = "";
// export const gaKey = "UA-162111085-1";
// export const gtmKey = "GTM-N9QX5S3";
// export const adminPass = "nerdDemo123";

// export const baseSchema = "https"
// export const baseUrl = "api.reeltime-ai.com";
// export const apiKey = "fusionComInstaPocMadrid_BmdJWWKeyd#W$Cv#L9*?Nem";
// export const dryRun = "0";
// export const contextString = "fusioncomunicacion_instagramMadridJune2020";
// export const headerTitle = "Universidad de Madrid - Instagram 1.03";
// export const partnerImgPath = "/img/fusion.jpg";
// export const gaKey = "UA-162111085-1";
// export const gtmKey = "GTM-N9QX5S3";
// export const adminPass = "jNR1fh7euTuv";